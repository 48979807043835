<template>
  <div>
    <Header></Header>
    <div class="cpal1">
      <img class="cpalA1" src="@/assets/cpal.png" alt="" />
      <div class="cpalB1">产品案例 - 智慧水利</div>
      <div class="cpalC1">INTELLIGENT WATER CONSERVANCY</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">智慧水利</div>
        <div class="bodyTitle">
          <div class="product">INTELLIGENT WATER CONSERVANCY</div>
          <!-- <div class="more">
            <div class="datetime">2022-10-23</div>
          </div> -->
        </div>
      </div>
      <div class="bodyContent">
        <div>
          <img class="cpalb1" src="@/assets/智慧水利.png" alt="" />
          <img class="cpalb1" src="@/assets/智慧水利2.png" alt="" />
        </div>
        <div class="fontA11">
          &emsp;&emsp;随着全球变化和经济社会的快速发展，我国水资源供需矛盾日益突出，极端水文事件频繁发生，严重威胁我国水安全、粮食安全及生态环境安全。我国地域广阔，水系众多，水利工程点多、面广、量大，类型复杂，经济社会的快速发展对水资源安全和水利工程的安全高效运行提出了更高的要求。水利部在2019年全国水利工作会议上提出，“水利工程补短板，水利工作强监管”是今后工作的总基调。当前，传统水利已难以充分满足新时代经济社会发展所需的专业化、精细化、智能化的管理要求。随着以云计算、Web2.0为标志的第三次信息技术浪潮的到来，以“感知、互联和智能”等为基本特点的大数据、物联网及其应用极大地改变了各个行业信息化服务的效率、易用性和行为范式。水利信息化、现代化和智能化发展迎来了良好契机，发展智慧水利，正成为水利现代化，快速提升水资源效能的强力抓手和必然选择。
        </div>
      </div>
      <div class="LastNext">
        <div class="last" @click="fiveL">上一案例：{{ last }}</div>
        <div class="next" @click="fiveN">下一案例：{{ next }}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      last: "智慧工地",
      next: "智慧农业",
    };
  },
  methods: {
    fiveL() {
        this.$router.push({ name: "productCase4" });
    },
    fiveN() {
        this.$router.push({ name: "productCase6" });
    }
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.datetime {
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.bodyContent {
  display: block;
  border-bottom: 1px solid #d8d8d8;
}
.cpalb1 {
    width: 100%;
  margin: 24px 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 46px 32px;
  line-height: 40px;
}
.LastNext {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.last {
    cursor: pointer;
}
.next {
    cursor: pointer;
}
.cpal1 {
  position: relative;
}
.cpalA1 {
  width: 100%;
}
.cpalB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.cpalC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
</style>
